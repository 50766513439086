<template>
  <div>
    <b-modal
      id="sidebar-add-new-event"
      ref="sidebar-add-new-event"
      :visible="isEventHandlerSidebarActive"
      bg-variant="white"
      hide-footer
      size="xl"
      :no-close-on-backdrop="true"
      :title="(eventLocal.id ? $t('calendar-template.update-event'): $t('calendar-template.add-event'))"
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
      @hidden="() => { $emit('close'); resetEventLocal(); }"
    >
      <template #default="{ hide }">
        <!-- Isolated Template Details -->
        <div
          v-if="isolatedTemplate"
          class="px-2"
        >
          <h5>
            {{ $t('template-module.isolated-template') }}:
            <span class="font-weight-normal">{{ isolatedTemplate.name }}</span>
          </h5>

          <h5>
            {{ $t('class-module.start-date') }}:
            <span class="font-weight-normal">{{ FORMAT_DATE(isolatedTemplate.startDate, 'YYYY-MM-DD') }}</span>
          </h5>

          <h5>
            {{ $t('class-module.end-date') }}:
            <span class="font-weight-normal">{{ FORMAT_DATE(isolatedTemplate.endDate, 'YYYY-MM-DD') }}</span>
          </h5>
        </div>
        <!-- Body -->
        <validation-observer
          v-slot="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <div v-if="!isEditMode">
              <b-button
                class="mr-1"
                :variant="activeTab == 'calendar' ? 'primary' : '' "
                @click="activeTab = 'calendar'"
              >
                {{ $t('actions.lessons') }}
              </b-button>
              <b-button
                v-if="!isolatedTemplate"
                class="mr-1"
                :variant="activeTab == 'class_test' ? 'primary' : '' "
                @click="activeTab = 'class_test'"
              >
                {{ $t('labels.tests') }}
              </b-button>
              <b-button
                class="mr-1"
                :variant="activeTab == 'instruction' ? 'primary' : '' "
                @click="activeTab = 'instruction'"
              >
                {{ $t('calendar-module.instruction') }}
              </b-button>
              <b-button
                class="mr-1"
                :variant="activeTab == 'conversational' ? 'primary' : '' "
                @click="activeTab = 'conversational'; eventLocal.extendedProps.lessonGroupId = null;"
              >
                {{ $t('calendar-module.conversational') }}
              </b-button>
              <b-button
                class="mr-1"
                :variant="activeTab == 'vocabWordsProblem' ? 'primary' : '' "
                @click="activeTab = 'vocabWordsProblem'; eventLocal.extendedProps.lessonGroupId = null;"
              >
                {{ $t('image-library-module.vocab-words') }}
              </b-button>
            </div>

            <div :class="!isEditMode && 'mt-2'">
              <div
                v-if="activeTab === 'calendar' || activeTab?.startsWith('class_test') || activeTab === 'follow_up' || activeTab === 'conversational'"
                title="Normal Event"
              >
                <b-row>
                  <b-col
                    md="6"
                    style="border-right: 1px solid #eeeeee;"
                  >
                    <b-row>
                      <b-col md="4">
                        <validation-provider
                          v-slot="validationContext"
                          :name="$t('class-module.grade')"
                          rules="required"
                        >
                          <b-form-group
                            :label="$t('class-module.grade')"
                            :state="getValidationState(validationContext)"
                          >
                            <b-form-select
                              id="event-lesson"
                              v-model="eventLocal.extendedProps.gradeId"
                              :options="grades"
                              @change="getCourses"
                            />
                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col>
                        <validation-provider
                          v-slot="validationContext"
                          :name="$t('actions.course')"
                          rules="required"
                        >
                          <b-form-group
                            :label="$t('actions.course')"
                            :state="getValidationState(validationContext)"
                          >
                            <b-form-select
                              id="event-lesson"
                              v-model="eventLocal.extendedProps.courseId"
                              :options="filteredCourses"
                              @change="getLessonGroups"
                            />
                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                    <validation-provider
                      v-slot="validationContext"
                      :name="$t('courses-module.LessonGroup')"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('courses-module.LessonGroup')"
                        :state="getValidationState(validationContext)"
                      >
                        <b-form-select
                          id="event-lesson"
                          v-model="eventLocal.extendedProps.lessonGroupId"
                          :disabled="!eventLocal.extendedProps.courseId"
                          :options="lessonGroups"
                          @change="getLessons"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                    <b-row>
                      <b-col v-if="eventLocal.extendedProps.hasTest && !isEditMode"
                             cols="3"
                      >
                        <b-form-group>
                          <template #label>
                            <div class="small">
                              {{ $t('calendar-module.test-type') }}
                            </div>
                          </template>
                          <b-form-select
                            v-model="testType"
                            :options="[
                              {
                                text: $t('calendar-module.normal-test'),
                                value: 'class_test'
                              },
                              {
                                text: $t('calendar-module.class-test-tgp'),
                                value: 'class_test_tgp'
                              }
                            ]"
                            @change="selectedTgpProblem = []"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <validation-provider
                          v-slot="validationContext"
                          :name="$t('labels.lesson')"
                          rules="required"
                        >
                          <b-form-group
                            label-for="event-lesson"
                            :state="getValidationState(validationContext)"
                            :set="isMultiple = (!eventLocal.id || eventLocal.extendedProps.hasTest) && testType !== 'class_test_tgp'"
                          >
                            <template #label>
                              <div>
                                {{ $t('labels.lesson') }}
                                <feather-icon
                                  v-if="!isMultiple"
                                  icon="ExternalLinkIcon"
                                  color="blue"
                                  role="button"
                                  @click="handleSelectedOptionClick({
                                    id: eventLocal.extendedProps.lessonId
                                  })"
                                />
                              </div>
                            </template>
                            <v-select
                              id="event-lesson"
                              v-model="eventLocal.extendedProps.lessonId"
                              :multiple="isMultiple"
                              label="text"
                              :placeholder="$t('messages.select-lesson')"
                              :reduce="(data) => data.id"
                              :disabled="!eventLocal.extendedProps.lessonGroupId"
                              :options="ARRAY_UNIQUE_BY_KEY(lessons.filter(lesson => lesson.id), 'id')"
                              :close-on-select="false"
                              @input="onLessonChange"
                            >
                              <template #option="{ text, value}">
                                <span :style="{ color: savedLessons.includes(value) ? '#28c76f !important' : '' }">
                                  {{ text }}-{{ value }}
                                </span>
                              </template>
                              <template #selected-option="option">
                                <div role="button"
                                     @click="handleSelectedOptionClick(option)"
                                >
                                  <span> {{ option.text }}</span>
                                </div>
                              </template>
                            </v-select>
                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <!-- Start Date -->
                        <validation-provider
                          v-slot="validationContext"
                          :name="$t('class-module.start-date')"
                          rules="required"
                        >

                          <b-form-group
                            :label="$t('class-module.start-date')"
                            label-for="start-date"
                            :state="getValidationState(validationContext)"
                          >
                            <b-form-datepicker
                              v-model="eventLocal.start"
                              class="form-control"
                              :min="new Date()"
                              @input="startDateChanged"
                            />
                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col>
                        <!-- End Date -->
                        <validation-provider
                          v-slot="validationContext"
                          :name="$t('class-module.end-date')"
                          rules="required"
                        >

                          <b-form-group
                            :label="$t('class-module.end-date')"
                            label-for="end-date"
                            :state="getValidationState(validationContext)"
                          >
                            <b-form-datepicker
                              id="example-datepicker"
                              v-model="eventLocal.end"
                              :min="eventLocal.start"
                              value-as-date
                              class="mb-1"
                            />
                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="6">
                        <b-form-group :label="$t('calendar-module.assignment-type')">
                          <b-form-input
                            v-if="isolatedTemplate"
                            value="Isolated"
                            disabled
                          />
                          <b-form-select
                            v-else
                            v-model="eventLocal.extendedProps.assignment_type"
                            :options="assignmentTypes"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <validation-provider
                          v-slot="validationContext"
                          :name="$t('calendar-module.event-order')"
                          rules="required"
                        >
                          <b-form-group :label="$t('calendar-module.event-order')">
                            <b-form-input
                              v-model.number="eventLocal.extendedProps.order_index"
                              type="number"
                              :placeholder="$t('calendar-module.enter-order-number')"
                              :state="getValidationState(validationContext)"
                            />
                          </b-form-group>
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </validation-provider>
                      </b-col>
                      <b-col v-if="eventLocal.extendedProps.assignment_type === 'private'"
                             cols="6"
                      >
                        <b-form-group label="Students">
                          <v-select
                            v-model="eventLocal.extendedProps.students"
                            multiple
                            :reduce="student => student.id"
                            label="fullname"
                            :placeholder="$t('class-test.select-student')"
                            :options="ARRAY_UNIQUE_BY_KEY(students, 'id')"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col v-if="isMultiple"
                             md="12 mb-2"
                      >
                        <b-row>
                          <b-col v-for="lessonId of eventLocal.extendedProps.lessonId"
                                 :key="lessonId"
                                 lg="12"
                          >
                            <WeakestSkillOption :has_multi_practice="has_multi_practice"
                                                :is-a-teacher="isATeacher"
                                                :default_engine="default_engine"
                                                :course-id="eventLocal.extendedProps.courseId"
                                                :lesson-id="lessonId"
                                                :is-conversational="eventData.conversational_mode"
                                                @updateMultiplePrice="bool => has_multi_practice = bool"
                            >
                              <h4>
                                {{ lessons.find(lesson => lesson.id == lessonId)?.text }}
                              </h4>
                            </WeakestSkillOption>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col md="12">
                        <b-row>
                          <b-col md="12"
                                 class="text-left"
                          >
                            <WeakestSkillOption
                              v-if="!isMultiple && testType != 'class_test_tgp'"
                              :is-a-teacher="isATeacher"
                              :course-id="eventLocal.extendedProps.courseId"
                              :lesson-id="eventLocal.extendedProps.lessonId"
                              :is-conversational="eventLocal.extendedProps.event_type === 'conversational'"
                              class="mb-2 mr-2 text-left"
                            />
                            <div>
                              <hr>
                              <b-row>
                                <b-col class="text-left border-right"
                                       cols="2"
                                >
                                  <b-button variant="outline-primary"
                                            :disabled="!eventLocal.extendedProps.lessonId && !eventLocal.extendedProps.lessonId.length"
                                            class="mb-2 mr-2"
                                            @click="showLessonPlanners()"
                                  >
                                    <feather-icon icon="CalendarIcon" />
                                  </b-button>
                                </b-col>
                                <b-col class="text-left">
                                  {{ $t('lesson-plan-info') }}
                                </b-col>
                              </b-row>
                            </div>
                            <b-button v-if="activeTab?.startsWith('class_test') && eventData.lesson_id[0] && testType == 'class_test_tgp'"
                                      variant="outline-primary"
                                      :disabled="!eventLocal.extendedProps.lessonId && !eventLocal.extendedProps.lessonId.length"
                                      class="mb-2 mr-2"
                                      @click="showTgp = true"
                            >
                              {{ eventLocal.id ? $t('template-module.update-tgp') : $t('template-module.add-tgp') }}
                            </b-button>
                            <b-button
                              v-if="eventLocal.id && event?.extendedProps?.event_type === 'follow_up'"
                              variant="outline-primary"
                              class="mr-2 mb-2"
                              :disabled="!eventLocal.extendedProps.lessonId && !eventLocal.extendedProps.lessonId.length"
                              @click="showFollowup = true"
                            >
                              {{ $t('template-module.update-follow-up') }}
                            </b-button>
                          </b-col>
                          <b-col v-if="selectedLessonPlans.length"
                                 md="6"
                          >
                            <h6
                              class="text-success cursor-pointer"
                              @click="showLessonPlannerInfo"
                            >
                              {{ selectedLessonPlans.length }} {{ $t('navigation-menu.lesson-plans') }}
                            </h6>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col md="6">
                    <b-row class="ml-1">
                      <ScheduleType
                        v-model="eventLocal.extendedProps.schedule_type"
                        :options="scheduleOptions"
                        :title="`${$t('calendar-module.select-schedule-type')}:`"
                      />

                    </b-row>
                    <class-test-form
                      v-if="eventLocal.extendedProps.hasTest"
                      ref="classTestForm"
                      :value="eventLocal.extendedProps.testInfo || {}"
                      :lessons="lessons"
                      :has-minimal-view="eventLocal.extendedProps.hasMinimalView"
                      :selected-tgp-problem="testType === 'class_test_tgp' ? selectedTgpProblem : []"
                      @selectedTgp="id => selectedTgpProblem = selectedTgpProblem.filter(item => item.code != id)"
                      @onTestInfoChange="(testInfo) => {
                        eventLocal.extendedProps.testInfo = testInfo
                      }"
                    />
                  </b-col>
                </b-row>

              </div>
              <div v-if="activeTab == 'instruction'"
                   title="Instruction"
                   lazy
              >
                <b-row>
                  <b-col>
                    <!-- Start Date -->
                    <validation-provider
                      v-slot="validationContext"
                      :name="$t('class-module.start-date')"
                      rules="required"
                    >

                      <b-form-group
                        :label="$t('class-module.start-date')"
                        label-for="start-date"
                        :state="getValidationState(validationContext)"
                      >
                        <b-form-datepicker
                          v-model="eventLocal.start"
                          class="form-control"
                          :min="new Date()"
                          @input="startDateChanged"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col>
                    <!-- End Date -->
                    <validation-provider
                      v-slot="validationContext"
                      :name="$t('class-module.end-date')"
                      rules="required"
                    >

                      <b-form-group
                        :label="$t('class-module.end-date')"
                        label-for="end-date"
                        :state="getValidationState(validationContext)"
                      >
                        <b-form-datepicker
                          id="example-datepicker"
                          v-model="eventLocal.end"
                          :min="eventLocal.start"
                          value-as-date
                          class="mb-1"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <!-- Start Date -->
                    <validation-provider
                      v-slot="validationContext"
                      :name="$t('template-module.event-title')"
                      rules="required"
                    >

                      <b-form-group
                        :label="$t('template-module.event-title')"
                        label-for="event-title"
                        :state="getValidationState(validationContext)"
                      >
                        <b-form-input
                          v-model="eventLocal.event_title"
                          class="form-control"
                          :placeholder="$t('template-module.event-title')"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col>
                    <!-- Start Date -->
                    <validation-provider
                      v-slot="validationContext"
                      :name="$t('template-module.event-link')"
                    >
                      <b-form-group
                        label-for="event-link"
                        :state="getValidationState(validationContext)"
                      >
                        <template #label>
                          <span>{{ $t('template-module.event-link') }}</span>
                          <feather-icon
                            v-if="eventLocal.event_link"
                            icon="ExternalLinkIcon"
                            class="ml-1 text-primary"
                            role="button"
                            size="20"
                            @click="openInstructionLink(eventLocal.event_link)"
                          />
                        </template>
                        <b-form-input
                          v-model="eventLocal.event_link"
                          class="form-control"
                          :placeholder="$t('template-module.event-link')"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <!-- Start Date -->
                    <validation-provider
                      v-slot="validationContext"
                      :name="$t('template-module.event-description')"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('template-module.event-description')"
                        label-for="event-description"
                        :state="getValidationState(validationContext)"
                      >
                        <b-form-textarea
                          v-model="eventLocal.event_description"
                          class="form-control"
                          :placeholder="$t('template-module.event-description')"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </div>

            </div>
          </b-form>
        </validation-observer>

        <div v-show="activeTab == 'vocabWordsProblem' || activeTab === 'vocab_words'"
             class="px-2"
        >
          <VocabLesson ref="vocabLesson"
                       :active-tab="activeTab"
                       :reset-form="resetForm"
                       :event-id="event?.id"
                       :get-validation-state="getValidationState"
                       @refetch-events="$emit('refetch-events'); hide();"
                       @remove-event="$emit('remove-event'); hide();"
                       @update="updateEvents"
          />
        </div>
        <!-- Form Actins -->
        <div v-if="!['lesson-plan', 'vocabWordsProblem', 'vocab_words'].includes(activeTab)"
             class="d-flex mt-2 justify-content-end"
        >
          <b-button
            v-if="eventLocal.id"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            class="mr-2"
            variant="outline-danger"
            @click="$emit('remove-event'); hide();"
          >
            {{ $t('actions.delete') }}
          </b-button>
          <!-- <template
            v-if="activeTab ==='vocabWordsProblem'"
          >
            <b-button
              class="ml-1"
              variant="primary"
              :disabled="$refs.vocabLesson?.isProcessing || $refs.vocabLesson?.selectedWords.length === 0"
              @click="$refs.vocabLesson?.createWordProblem"
            >
              <b-spinner
                v-if="$refs.vocabLesson?.isProcessing"
                class="mr-1"
                small
                variant="light"
              />
              {{ $t('actions.create-problem') }}
            </b-button>
          </template> -->

          <b-button
            variant="primary"
            :disabled="isProcessing"
            @click="submitData()"
          >
            <b-spinner
              v-if="isProcessing"
              class="mr-1"
              small
              variant="light"
            />
            <span v-if="isolatedTemplate && !eventLocal.id">
              {{ $t('template-module.add-close') }}
            </span>
            <span v-else>
              {{ eventLocal.id ? $t('actions.update') : $t('actions.add') }}
            </span>
          </b-button>
          <b-button
            v-if="isolatedTemplate && !eventLocal.id"
            class="ml-1"
            variant="primary"
            :disabled="isProcessing"
            @click="submitData(true)"
          >
            <b-spinner
              v-if="isProcessing"
              class="mr-1"
              small
              variant="light"
            />
            {{ $t('template-module.add-and-create-another') }}
          </b-button>

        </div>
      </template>
    </b-modal>
    <b-modal
      id="lesson-planner-modal"
      hide-footer
      size="xl"
    >
      <lesson-planner
        :courses="courses"
        :all-lesson-id="allLessonId"
        :lesson-planner-id="selectedLessonId"
        :updating="true"
        :selected-plans="selectedLessonPlans"
        :start-date="eventLocal.start"
        @updateSelectedPlans="(value)=>{selectedLessonPlans = value}"
      />
    </b-modal>
    <b-modal
      :id="isolatedTemplate ? 'isolatedLessonPlannerInfoModal' : 'lessonPlannerInfoModal'"
      hide-footer
      size="lg"
      :title="$t('template-module.selected-lesson-plans')"
    >
      <div
        v-if="selectedLessonPlans.length"
        class="d-flex flex-wrap align-items-center"
        style="gap:20px;"
      >
        <div v-for="lessonPlan,index in selectedLessonPlans"
             :key="index"
        >
          <b-badge variant="success">
            {{ lessonPlan.text }}
          </b-badge>
        </div>
      </div>
      <p v-else
         class="text-center"
      >
        {{ $t('template-module.no-lesson-plans-found') }}
      </p>
    </b-modal>
    <follow-up v-if="event?.id && showFollowup"
               :student-id="+event.extendedProps.student_id"
               :lesson-id="+event.extendedProps.lessonId"
               :class-id="+$route.params.classId"
               :event-id="+event.id"
               @close="showFollowup = false"
    />
    <b-modal v-if="showTgp"
             :visible="true"
             size="xl"
             :title="$t('template-module.teacher-created-test')"
             @close="showTgp = false"
             @hide="showTgp = false"
    >
      <TeacherCreateProblem
        :lesson-id="eventData.lesson_id[0]"
        :selected-problems="selectedTgpProblem"
        @updateProblem="value => selectedTgpProblem = value"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BForm, BFormGroup, BButton, BFormInvalidFeedback, BFormSelect, BRow, BCol, BFormDatepicker, BFormInput, BFormTextarea, BBadge, BSpinner,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, url } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, toRefs } from 'vue'
import vSelect from 'vue-select'
import moment from 'moment'
import useJwt from '@/auth/jwt/useJwt'
import { USER_TYPE_TEACHER } from '@/const/userType'
import LessonPlanner from '@/views/teacher/class/lesson-planner/Index.vue'
import FollowUp from '@/views/common/lessons/components/FollowUp.vue'
import TeacherCreateProblem from '@/views/common/lessons/components/teacher-problem/index.vue'
import useApollo from '@/@core/graphql/useApollo'
import useCalendarEventHandler from './useCalendarEventHandler'
import ClassTestForm from '../class-test-form/index.vue'
import WeakestSkillOption from './WeakestSkillOption.vue'
import ScheduleType from './ScheduleType.vue'
import VocabLesson from '../vocab-lesson/index.vue'

export default {
  components: {
    BButton,
    BFormSelect,
    BModal,
    BForm,
    BFormGroup,
    ValidationProvider,
    TeacherCreateProblem,
    BFormInvalidFeedback,
    BRow,
    BCol,
    ValidationObserver,
    BFormDatepicker,
    ClassTestForm,
    BFormInput,
    BFormTextarea,
    vSelect,
    LessonPlanner,
    BBadge,
    WeakestSkillOption,
    BSpinner,
    FollowUp,
    ScheduleType,
    VocabLesson,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEventHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  },
  props: {
    openInstructionLink: {
      type: Function,
      default: () => {},
    },
    isEventHandlerSidebarActive: {
      type: Boolean,
      default: () => false,
    },
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      default: () => {},
    },
    allEventsList: {
      type: Array,
      default: () => [],
    },
    grades: {
      type: Array,
      default: () => [],
    },
    isolatedTemplate: {
      type: Object,
      default: () => null,
    },
    requireRefetchEmit: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props, { emit }) {
    /*
     ? This is handled quite differently in SFC due to deadlock of `useFormValidation` and this composition function.
     ? If we don't handle it the way it is being handled then either of two composition function used by this SFC get undefined as one of it's argument.
     * The Trick:

     * We created reactive property `clearFormData` and set to null so we can get `resetEventLocal` from `useCalendarEventHandler` composition function.
     * Once we get `resetEventLocal` function which is required by `useFormValidation` we will pass it to `useFormValidation` and in return we will get `clearForm` function which shall be original value of `clearFormData`.
     * Later we just assign `clearForm` to `clearFormData` and can resolve the deadlock. 😎

     ? Behind The Scene
     ? When we passed it to `useCalendarEventHandler` for first time it will be null but right after it we are getting correct value (which is `clearForm`) and assigning that correct value.
     ? As `clearFormData` is reactive it is being changed from `null` to corrent value and thanks to reactivity it is also update in `useCalendarEventHandler` composition function and it is getting correct value in second time and can work w/o any issues.
    */
    const clearFormData = ref(null)

    const {
      eventLocal,
      resetEventLocal,
      calendarOptions,

      // UI
      onSubmit,
      guestsOptions,
    } = useCalendarEventHandler(toRefs(props), clearFormData, emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetEventLocal, props.clearEventData)

    clearFormData.value = clearForm

    return {
      // Add New Event
      eventLocal,
      resetEventLocal,
      calendarOptions,
      onSubmit,
      guestsOptions,

      // Form Validation
      resetForm,
      refFormObserver,
      getValidationState,
    }
  },
  data() {
    return {
      required,
      email,
      url,
      activeTab: this.event?.extendedProps?.event_type || 'calendar',
      courses: [],
      lessons: [],
      lessonGroups: [],
      selectedLessonPlans: [],
      selectedLessonId: null,
      firstLessonId: null,
      self: this.AUTH_USER(),
      has_multi_practice: false,
      default_engine: '',
      isMultiple: false,
      isProcessing: false,
      showFollowup: false,
      showTgp: false,
      selectedTgpProblem: [],
      testType: 'class_test',
      students: [],
      assignmentTypes: [
        {
          text: this.$i18n.tc('template-module.public'),
          value: 'public',
        },
        {
          text: this.$i18n.tc('template-module.private'),
          value: 'private',
        },
      ],
      scheduleOptions: [
        {
          value: 'sequenced',
          label: this.$i18n.tc('calendar-module.sequenced'),
          info: this.$i18n.tc('calendar-module.sequenced-info'),
        },
        {
          value: 'non-sequenced',
          label: this.$i18n.tc('calendar-module.non-sequenced'),
          info: this.$i18n.tc('calendar-module.non-sequenced-info'),
        },
        {
          value: 'unlockable-sequenced',
          label: this.$i18n.tc('calendar-module.unlockable-sequenced'),
          info: this.$i18n.tc('calendar-module.unlockable-sequenced-info'),
        },
      ],
    }
  },

  computed: {
    isATeacher() {
      return this.self.usertype === USER_TYPE_TEACHER
    },
    eventType() {
      if (this.eventLocal.id) {
        return this.eventLocal.extendedProps?.event_type
      }
      return this.activeTab
    },
    isEditMode() {
      return !!this.eventLocal?.id
    },
    savedLessons() {
      return this.allEventsList.map(item => item.extendedProps.lessonId)
    },
    filteredCourses() {
      const isHomeWork = !this.eventType?.startsWith('class_test')
      if (isHomeWork) {
        return this.courses.filter(item => !item.lesson_groups || item.lesson_groups.some(groupItem => groupItem.lessons.some(lesson => lesson.use_on === 'all' || lesson.use_on === 'homework')))
      }
      return this.courses.filter(item => !item.lesson_groups || item.lesson_groups.some(groupItem => groupItem.lessons.some(lesson => lesson.use_on !== 'homework')))
    },
    eventData() {
      return {
        id: this.eventLocal.id || null,
        lesson_id: this.eventLocal.extendedProps.lessonId,
        course_id: this.eventLocal.extendedProps.courseId,
        lesson_group_id: this.eventLocal.extendedProps.lessonGroupId,
        start_time: this.formatDate(this.eventLocal.start),
        end_time: this.formatDate(this.eventLocal.end),
        hasTest: this.eventLocal.extendedProps.hasTest,
        event_type: this.activeTab === 'conversational' ? 'conversational' : this.eventLocal.extendedProps.event_type,
        event_title: this.eventLocal.event_title,
        event_link: this.eventLocal.event_link,
        event_description: this.eventLocal.event_description,
        testInfo: this.eventLocal.extendedProps.testInfo,
        nonSequence: this.eventLocal.extendedProps.nonSequence,
        schedule_type: this.eventLocal.extendedProps.schedule_type,
        selected_event_type: this.eventType,
        content: this.eventLocal.extendedProps?.content,
        lesson_planner_type: this.eventLocal.extendedProps?.lesson_planner_type,
        title: this.eventLocal.title,
        access_type: this.eventLocal.extendedProps?.access_type,
        lesson_planner_id: this.selectedLessonPlans.map(item => item.id),
        default_engine: this.default_engine,
        has_multi_practice: this.has_multi_practice,
        tgp_problems: this.selectedTgpProblem.map(item => item.code),
        test_type: this.testType,
        assignment_type: this.eventLocal.extendedProps?.assignment_type,
        order_index: this.eventLocal.extendedProps?.order_index,
        event_students: this.eventLocal.extendedProps.students,
        template_id: this.eventLocal.extendedProps.template_id ?? null,
        conversational_mode: this.activeTab === 'conversational',
        skill_type: this.activeTab === 'conversational' ? this.eventLocal.extendedProps.skill_type : null,
      }
    },
    allLessonId() {
      if (typeof this.eventLocal.extendedProps.lessonId === 'number') {
        // eslint-disable-next-line eqeqeq
        return [{ value: this.eventLocal.extendedProps.lessonId, text: this.lessons.find(lesson => lesson.id == this.eventLocal.extendedProps.lessonId)?.text }]
      }
      if (!this.eventLocal.extendedProps.lessonId || !this.eventLocal.extendedProps.lessonId.length) return []

      return this.eventLocal.extendedProps.lessonId.map(item => ({
        value: item,
        text: this.lessons.find(lesson => lesson.id === item)?.text,
      }))
    },
  },
  watch: {
    'eventData.assignment_type': {
      handler(value) {
        if (value === 'private') {
          this.onChangeAssignmentType(value)
        }
      },
      immediate: true,
    },
    'eventLocal.extendedProps.gradeId': {
      handler() {
        this.setNull('courseId')
      },
      deep: true,
    },
    'eventLocal.extendedProps.courseId': {
      handler() {
        this.setNull('lessonGroupId')
      },
      deep: true,
    },
    'eventLocal.extendedProps.lessonGroupId': {
      handler() {
        this.setNull('lessonId', true)
      },
      deep: true,
    },
    event() {
      if (this.event.start) this.event.start = this.formatDate(this.event.start)
      if (!this.event.end) this.event.end = this.event.start
    },
    activeTab: {
      handler(value) {
        if (value?.startsWith('class_test')) {
          this.eventLocal.extendedProps.hasTest = true
          this.testType = value
        } else {
          this.eventLocal.extendedProps.hasTest = false
        }
      },
      immediate: true,
    },
  },
  mounted() {
    // eslint-disable-next-line no-unused-expressions
    this.event.extendedProps.gradeId && this.getCourses(this.event.extendedProps.gradeId)
    if (this.event.extendedProps.courseId) this.getLessonGroups(this.event.extendedProps.courseId)
    if (this.event.extendedProps.lessonGroupId) this.getLessons(this.event.extendedProps.lessonGroupId)
    if (this.event.extendedProps.lessonId) {
      this.firstLessonId = this.event.extendedProps.lessonId
    }
    if (this.event.extendedProps.lessonPlans && this.event.extendedProps.lessonPlans.length) {
      this.selectedLessonPlans = this.event.extendedProps.lessonPlans
    }
    if (this.event.id && this.event.extendedProps.event_type === 'class_test_tgp') {
      this.getProblemFromEvent()
    }
  },
  methods: {
    onChangeAssignmentType(value) {
      if (value === 'private' && !this.students.length) {
        useApollo.getClassRoomStudents(this.$route.params.classId).then(response => {
          this.students = response.data?.room?.students.map(student => ({
            ...student,
            id: Number(student.id),
          }))
        }).catch(error => {
          this.showErrorMessage(error)
        })
      }
    },
    setNull(field, multiple) {
      if (multiple) this.eventLocal.extendedProps[field] = []
      else this.eventLocal.extendedProps[field] = null
    },
    canViewTab(nameArray) {
      return !this.eventLocal.id || nameArray.includes(this.eventData.event_type)
    },
    showLessonPlanners() {
      if (typeof this.eventLocal.extendedProps.lessonId === 'number') {
        this.selectedLessonId = this.eventLocal.extendedProps.lessonId
      } else {
        // eslint-disable-next-line prefer-destructuring
        this.selectedLessonId = this.eventLocal.extendedProps.lessonId[0]
      }
      this.$bvModal.show(this.isolatedTemplate ? 'isolatedLessonPlannerInfoModal' : 'lesson-planner-modal')
    },

    showLessonPlannerInfo() {
      // this.getLessonPlanners()
      this.$bvModal.show(this.isolatedTemplate ? 'isolatedLessonPlannerInfoModal' : 'lessonPlannerInfoModal')
    },
    onLessonChange(id) {
      const lesson = this.lessons.find(item => item.id === (Array.isArray(id) ? id[0] : id))
      if (lesson.onlyTest || this.testType === 'class_test_tgp') {
        this.eventLocal.extendedProps.hasTest = true
        setTimeout(() => {
          this.eventLocal.extendedProps.lessonId = [lesson.id]
        }, 500)
      }
      if (typeof this.eventLocal.extendedProps.lessonId === 'number') {
        this.selectedLessonPlans = []
      }
      if (this.firstLessonId === id) {
        this.selectedLessonPlans = this.event.extendedProps.lessonPlans
      }
    },
    handleSelectedOptionClick(item) {
      window.open(`/teacher/class/${this.$route.params.classId}/lesson/${item.id}`, '_blank')
    },
    startDateChanged() {
      if (new Date(this.eventLocal.end).getTime() < new Date(this.eventLocal.start).getTime()) {
        this.eventLocal.end = this.eventLocal.start
      }
    },
    addNewEvents(createAnother) {
      this.$emit('add-event', this.eventData, bool => {
        this.isProcessing = false
        if (bool) {
          if (createAnother) {
            this.resetForm()
          } else {
            this.$emit('update:is-event-handler-sidebar-active', false)
            this.$bvModal.hide()
            this.activeTab = 'calendar'
          }
        }
      })
    },
    updateEvents() {
      this.$emit('update-event', this.eventData, bool => {
        this.isProcessing = false
        if (bool) {
          this.$bvModal.hide()
          this.$emit('update:is-event-handler-sidebar-active', false)
          this.activeTab = 'calendar'

          if (this.requireRefetchEmit) {
            this.$emit('refetch')
          }
        }
      })
    },
    submitData(createAnother = false) {
      this.$refs.refFormObserver.validate().then(async success => {
        const isClassTestValid = await this.$refs.classTestForm?.$refs.classTestForm.validate()
        if (success && (isClassTestValid || !this.$refs.classTestForm)) {
          this.isProcessing = true
          if (!this.eventLocal.id) {
            this.addNewEvents(createAnother)
          } else {
            this.updateEvents()
          }
        }
      })
    },
    formatDate(date) {
      try {
        return moment(date?.split('T')[0]).format('YYYY-MM-DD')
      } catch {
        return moment(date).format('YYYY-MM-DD')
      }
    },
    getLessonGroups(courseId) {
      this.$store.dispatch('calendar/getLessonGroups', courseId)
        .then(response => { this.lessonGroups = response })
    },
    getLessons(gid) {
      // console.log('here', this.eventData.conversational_mode)
      // this.$store.dispatch('calendar/getLessons', gid, this.eventData.conversational_mode)
      //   .then(response => { this.lessons = response })

      useJwt.getLesson(gid, undefined, undefined, undefined, this.eventData.conversational_mode).then(res => {
        const data = res.data.data.data.map(({ id, name }) => ({
          id,
          value: id,
          text: name,
        }))
        data.unshift({ value: null, text: this.$t('messages.select-lesson') })
        this.lessons = data
      }).catch(err => this.showErrorMessage(err))
    },
    refetchEvents() {
      this.$bvModal.hide('sidebar-add-new-event')
      this.$emit('refetch-events')
    },
    getCourses() {
      this.courses = this.grades
        .find(grade => grade.value === this.eventLocal.extendedProps.gradeId)
        ?.courses.map(({ id, name }) => ({
          value: id,
          text: name,
        })) || []
      this.courses.unshift({ value: null, text: this.$i18n.tc('class-module.select-a-course') })
    },
    getProblemFromEvent() {
      useJwt.getProblemFromFollowUpEvent(this.eventLocal?.id, {
        params: {
          onlyProblems: true,
        },
      }).then(response => {
        this.selectedTgpProblem = response.data.data.followUpProblems.map(item => ({
          code: item.id,
          label: `${item.name} - ${item.problem_type?.name} (${item.skill_type})`,
        }))
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
// @import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
<style lang="scss">
.muted {
  color: #777; /* Define your muted text color here */
}

.italic {
  font-style: italic;
}

.schedule-type{
  // background: #eee;
  border: 1px solid #ddd;
}
.dark-layout {
  .schedule-type {
    background-color: #182034;
  }
}
.fc-h-event{
  border: none;
}
.fc-h-event .fc-event-title-container .fc-event-title {
  color: #1e1b1b !important;
}
.event-sequenced {
  background-color: #a39de9;
  border-left: 3px solid #7367f0
}
.event-follow-up {
  background-color: #ab87ab;
  border-left: 3px solid #b502b5;
}
.event-non-sequenced {
  background-color: #efd661;
  border-left: 3px solid #FFD300;
}
.event-class-test {
  background-color: #ea5455;
  border-left: 3px solid #e90a0c;
}
.event-conversational {
  background-color: #67b564;
  border-left: 3px solid #0fa908;
}
.event-instruction {
  background-color: #41b373;
  border-left: 3px solid #28c76f;
}
.event-unlockable-sequenced {
  background-color: #00cfe8;
  border-left: 3px solid #00cfe8;
}
</style>
