<template>
  <b-modal
    hide-footer
    :visible="show"
    ok-variant="primary"
    ok-title="Accept"
    modal-class="modal-success"
    centered
    size="lg"
    :title="$t('template-module.create-template')"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <b-overlay :show="isProcessing">
      <validation-observer ref="templateSaveRule">
        <div class="border p-1">
          <b-form-group :label="$t('template-module.template-type')">
            <b-form-radio-group
              v-model="templateType"
              name="template-type"
              :options="templateTypeOptions"
              button-variant="outline-primary"
              buttons
            />
          </b-form-group>
          <b-row>
            <b-col
              md="4"
              class="p-1"
            >
              <b-form-group :label="$t('template-module.template-name')">
                <validation-provider
                  v-slot="{ errors }"
                  name="name"
                  rules="required"
                  vid="email"
                >
                  <b-form-input
                    v-model="form.name"
                    :placeholder="$t('template-module.template-name')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4"
                   class="p-1"
            >
              <b-form-group :label="$t('class-module.start-date')">
                <validation-provider
                  v-slot="{ errors }"
                  name="start-date"
                  rules="required"
                >
                  <b-form-datepicker
                    v-model="form.startDate"
                    :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4"
                   class="p-1"
            >
              <b-form-group :label="$t('class-module.end-date')">
                <validation-provider
                  v-slot="{ errors }"
                  name="end-date"
                  rules="required"
                >
                  <b-form-datepicker
                    v-model="form.endDate"
                    :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-checkbox v-model="form.is_public"
                               :value="1"
                               switch
              >
                {{ $t('calendar-template.share-template') }}
              </b-form-checkbox>
            </b-col>
            <b-col v-if="form.is_public"
                   md="4"
            >
              <b-form-checkbox v-model="form.outside_school"
                               :value="1"
                               switch
              >
                {{ $t('template-module.outside-school') }}
              </b-form-checkbox>
            </b-col>
            <b-col md="2">
              <b-form-group>
                <b-button
                  block
                  variant="relief-primary"
                  :disabled="!!isSaving"
                  @click="onSubmit"
                >
                  <b-spinner
                    v-show="isSaving"
                    small
                  />
                  {{ $t('account-setting-general.save') }}
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </validation-observer>
      <b-tabs
        v-model="tabIndex"
        pills
      >
        <b-tab :title="$t('template-module.private-templates')">
          <template-list
            :template-list="templateList"
            @showEvent="handleShowEvent"
            @update-event="$emit('update-event')"
            @refetch="getTemplate"
          />
        </b-tab>
        <b-tab :title="$t('template-module.public-templates')">
          <PublicTemplate
            @showEvent="handleShowEvent"
            @refetch="getTemplate"
          />
        </b-tab>
        <b-tab :title="$t('template-module.isolated-template')">
          <isolated-template
            @showEvent="id => handleShowEvent(id, 'isolated')"
            @addEvent="onAddEvent"
          />
        </b-tab>
      </b-tabs>
      <b-modal
        :visible="!!selectedTemplate"
        ok-only
        hide-footer
        :ok-title="$t('accept')"
        modal-class="modal-primary"
        centered
        size="xl"
        :title="$t('template-module.event-list')"
        @close="selectedTemplate = null"
        @hide="selectedTemplate = null"
      >
        <div>
          <show-events
            :template-id="Number(selectedTemplate)"
            :type="tabIndex === 2 ? 'isolated' : ''"
          />
        </div>
      </b-modal>
    </b-overlay>

    <b-modal
      id="add-lessons-confirm-modal"
      :title=" $t('template-module.add-lessons')"
      :ok-title="$t('adaptive-learning-module.yes')"
      @ok="onLessonAddConfirm"
    >
      <p>{{ $t('template-module.add-lessons-to-template') }}</p>
    </b-modal>
  </b-modal>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import {
  BModal,
  BFormGroup,
  BFormDatepicker,
  BRow,
  BCol,
  BButton,
  BFormInput,
  BSpinner,
  BOverlay,
  BTabs,
  BTab,
  BFormCheckbox,
  BFormRadioGroup,
} from 'bootstrap-vue'
import {
  reactive, ref, watch, getCurrentInstance,
} from 'vue'
import i18n from '@/libs/i18n'
import useJwt from '@/auth/jwt/useJwt'
import moment from 'moment'
import TemplateList from './TemplateList.vue'
import ShowEvents from './ShowEvents.vue'
import PublicTemplate from './PublicTemplate.vue'
import IsolatedTemplate from './IsolatedTemplate.vue'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormDatepicker,
    BRow,
    BCol,
    BButton,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    PublicTemplate,
    TemplateList,
    BSpinner,
    ShowEvents,
    BOverlay,
    BTabs,
    BTab,
    BFormCheckbox,
    BFormRadioGroup,
    IsolatedTemplate,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    classId: {
      type: Number,
      default: 0,
    },
  },
  setup(prop) {
    const instance = getCurrentInstance()
    const root = instance.proxy.$root
    const isSaving = ref(false)
    const tabIndex = ref(0)
    const templateList = ref([])
    const selectedTemplate = ref(null)
    const isProcessing = ref(false)
    const templateType = ref('general')
    const templateTypeOptions = ref([
      {
        text: i18n.tc('template-module.general-template'),
        value: 'general',
      },
      {
        text: i18n.tc('template-module.isolated-template'),
        value: 'isolated',
      },
    ])
    const isolatedTemplate = ref(null)
    const grades = ref([])
    const form = reactive({
      name: '',
      startDate: new Date(),
      endDate: root.ADD_DATE(new Date(), 10),
    })
    const templateSaveRule = ref(null)
    const getTemplate = () => {
      useJwt
        .getCalendarTemplate({
          params: {
            class_id: prop.classId,
          },
        })
        .then(response => {
          templateList.value = response.data?.data?.template
        })
    }
    if (prop.classId) {
      getTemplate()
    }
    watch(() => prop.classId, newValue => {
      if (newValue) {
        getTemplate()
      }
    })

    watch(templateType, value => {
      if (value === 'isolated') tabIndex.value = 2
      else if (tabIndex.value === 2) tabIndex.value = 0
    })

    watch(tabIndex, value => {
      if (value === 2) templateType.value = 'isolated'
      else templateType.value = 'general'
    })

    const showAddEventModal = () => {
      const payload = {
        grades: grades.value,
        template: isolatedTemplate.value,
      }

      instance.proxy.$emit('add-event', payload)
    }

    const getGradesList = () => {
      useJwt.getGradeLists().then(response => {
        grades.value = response.data.data.grades.map(grade => ({
          text: grade.name,
          value: grade.id,
          courses: grade.courses,
        }))
        grades.value.unshift({
          text: 'Please select a grade',
          value: null,
          disabled: true,
        })
      }).finally(() => {
        showAddEventModal()
      })
    }

    const onSubmit = () => {
      templateSaveRule.value.validate().then(success => {
        if (success) {
          isSaving.value = true
          isProcessing.value = true
          useJwt
            .storeCalendarTemplate({
              name: form.name,
              class_id: templateType.value === 'isolated' ? null : prop.classId,
              start_date: moment(form.startDate).format('YYYY-MM-DD'),
              end_date: moment(form.endDate).format('YYYY-MM-DD'),
              is_public: form.is_public,
              outside_class: form.outside_school,
            })
            .then(response => {
              root.showSuccessMessage(response)
              form.name = ''
              form.startDate = new Date()

              if (templateType.value === 'isolated') {
                const { data } = response.data
                isolatedTemplate.value = {
                  id: data.id,
                  name: data.name,
                  startDate: data.start_date,
                  endDate: data.end_date,
                }
              }
              getTemplate()
              templateSaveRule.value.reset()
            })
            .catch(error => {
              if (error.response?.data?.data) {
                const errors = Object.values(error.response?.data?.data)
                errors.flat().forEach(message => {
                  root.showError(message)
                })
              } else {
                root.showErrorMessage(error)
              }
            })
            .finally(() => {
              isProcessing.value = false
              isSaving.value = false

              if (templateType.value === 'isolated') {
                root.$bvModal.show('add-lessons-confirm-modal')
              }
            })
        }
      })
    }

    const onLessonAddConfirm = () => {
      if (!grades.value.length) getGradesList()
      else showAddEventModal()
    }

    const onAddEvent = ({
      id, name, start_date, end_date,
    }) => {
      isolatedTemplate.value = {
        id,
        name,
        startDate: start_date,
        endDate: end_date,
      }

      onLessonAddConfirm()
    }

    const handleShowEvent = (template, type = 'general') => {
      selectedTemplate.value = template
      if (type === 'isolated') {
        selectedTemplate.value = template.id
        const {
          id, name, start_date, end_date,
        } = template
        isolatedTemplate.value = {
          id,
          name,
          startDate: start_date,
          endDate: end_date,
        }
      }
    }
    return {
      form,
      required,
      templateSaveRule,
      isSaving,
      templateList,
      selectedTemplate,
      isProcessing,
      templateType,
      templateTypeOptions,
      grades,
      tabIndex,
      isolatedTemplate,
      onSubmit,
      handleShowEvent,
      getTemplate,
      onLessonAddConfirm,
      onAddEvent,
    }
  },
}
</script>
