<template>
  <b-overlay :show="isProcessing">
    <validation-observer ref="refFormObserver">
      <b-row>
        <b-col>
          <b-row>
            <b-col
              md="6"
            >
              <b-form-group :label="$t('image-library-module.upload-csv-file')">
                <b-form-file
                  v-model="wordCsv"
                  :browse-text="$t('browseButton')"
                  :placeholder="$t('issue-report-module.choose-a-file')"
                  accept=".csv"
                  @input="uploadVocabCsv"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="6"
            >
              <b-form-group :label="$t('search-vocab')">
                <b-form-input
                  v-model="searchWord"
                  :placeholder="$t('search-vocab')"
                  @change="searchWordFromIL"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Students"
                rules="required"
              >
                <b-form-group :label="$t('class-test.select-student')">
                  <v-select
                    v-model="studentsForWords"
                    multiple
                    :reduce="student => student.id"
                    label="fullname"
                    :placeholder="$t('class-test.select-student')"
                    :options="ARRAY_UNIQUE_BY_KEY(studentsList, 'id')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <b-form-group :label="$t('class-test.select-answer-delivery-option')">
                <v-select
                  v-model="answerDeliveryOption"
                  :reduce="ansDeliveryOption => ansDeliveryOption.value"
                  :placeholder="$t('class-test.select-answer-delivery-option')"
                  :options="ansDeliveryOptions"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <!-- Start Date -->
              <validation-provider
                v-slot="validationContext"
                :name="$t('class-module.start-date')"
                rules="required"
              >

                <b-form-group
                  :label="$t('class-module.start-date')"
                  label-for="start-date"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-datepicker
                    v-model="wordStartDate"
                    class="form-control"
                    :min="new Date()"
                    @input="vocabStartDateChanged"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <!-- End Date -->
              <validation-provider
                v-slot="validationContext"
                :name="$t('class-module.end-date')"
                rules="required"
              >

                <b-form-group
                  :label="$t('class-module.end-date')"
                  label-for="end-date"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-datepicker
                    id="example-datepicker"
                    v-model="wordEndDate"
                    :min="wordStartDate"
                    class="mb-1"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <template
              v-if="isSearching"
            >
              <b-col md="12"
                     class="text-center"
              >
                <b-spinner
                  class="mr-1"
                  variant="primary"
                />
              </b-col>
            </template>
            <template v-else>
              <template
                v-if="searchedWords.length > 0"
              >
                <b-col
                  v-for="word, index in searchedWords"
                  :key="index"
                  md="4"
                >
                  <b-form-checkbox
                    :id="`word-${index}`"
                    v-model="selectedWords"
                    :value="word"
                  >
                    <div class="border-bottom">
                      {{ word.vocab_word }}
                    </div>
                    <div
                      v-if="word.synonyms && word.synonyms.length"
                      class="item-wrapper"
                    >
                      <p class="item-price">
                        {{ $t('image-library-module.synonyms') }}:  {{ word.synonyms?.map(item => item.text).toString() }}
                      </p>
                    </div>
                  </b-form-checkbox>
                </b-col>
              </template>
              <template
                v-else
              >
                <b-col md="12">
                  <p class="text-center">
                    {{ $t('messages.no-data-available') }}
                  </p>
                </b-col>
              </template>
            </template>
          </b-row>
        </b-col>
        <b-col
          md="5"
        >
          <b-row>
            <template v-if="selectedWords.length >0">
              <b-col
                v-for="word,windex in selectedWords"
                :key="windex"
                class="mb-1"
                md="6"
                sm="12"
              >
                <div class="border-bottom">
                  {{ word.vocab_word }}
                  <span
                    v-b-tooltip="`Remove word`"
                    class="float-right text-danger"
                    style="cursor: pointer;"
                    @click="removeWord(windex)"
                  >
                    X
                  </span>
                </div>
                <div
                  v-if="word.synonyms && word.synonyms.length"
                  class="item-wrapper"
                >
                  <p class="item-price">
                    {{ $t('image-library-module.synonyms') }}:  {{ word.synonyms?.map(item => item.text).toString() }}
                  </p>
                </div>
              </b-col>
            </template>
            <template v-else>
              <b-col>
                <p class="text-danger text-center">
                  Please select vocab words to create problem
                </p>
              </b-col>
            </template>
            <template v-if="missingWords.length > 0">
              <span class="border-bottom mr-1">Missing words:</span>
              <span v-for="missingWord, index in missingWords"
                    :key="index"
                    class="text-danger mr-1"
              >
                {{ missingWord }}
              </span>
            </template>
          </b-row>
        </b-col>
      </b-row>
      <div class="d-flex mt-2 justify-content-end">
        <b-button
          v-if="eventId"
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="reset"
          class="mr-2"
          variant="outline-danger"
          @click="$emit('remove-event');"
        >
          {{ $t('actions.delete') }}
        </b-button>
        <b-button
          v-if="!eventId"
          class="ml-1"
          variant="primary"
          :disabled="isProcessing || selectedWords.length === 0"
          @click="createWordProblem"
        >
          <b-spinner
            v-if="isProcessing"
            class="mr-1"
            small
            variant="light"
          />
          {{ eventId ? $t('actions.update') : $t('actions.create-problem') }}
        </b-button>
      </div>
    </validation-observer>
  </b-overlay>
</template>
<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormFile,
  BButton,
  BFormInput,
  BFormDatepicker,
  BFormCheckbox,
  BSpinner,
  BFormInvalidFeedback,
  VBTooltip,
  BOverlay,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'
import useApollo from '@/@core/graphql/useApollo'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormFile,
    BFormInput,
    BFormDatepicker,
    BFormCheckbox,
    BSpinner,
    BButton,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: ['resetForm', 'getValidationState', 'activeTab', 'eventId'],
  data() {
    return {
      wordCsv: [],
      isProcessing: false,
      selectedWords: [],
      studentsForWords: [],
      isSearching: false,
      wordStartDate: '',
      wordEndDate: '',
      answerDeliveryOption: '',
      searchWord: '',
      searchedWords: [],
      missingWords: [],
      studentsList: [],
      ansDeliveryOptions: [
        {
          label: 'Example Sentence',
          value: 'example_sen',
        },
        {
          label: 'Description Sentence',
          value: 'desc_sen',
        },
      ],
    }
  },
  computed: {
    isEditMode() {
      return !!this.eventId
    },
  },
  watch: {
    activeTab(value) {
      if (value === 'vocabWordsProblem') {
        this.getStudentsForVw()
      }
    },
    eventId: {
      handler(value) {
        if (value) {
          this.getEventDetail()
        }
      },
      immediate: true,
    },
  },
  methods: {
    getEventDetail() {
      this.getStudentsForVw()
      this.isProcessing = true
      useJwt.getVocabEventInfo(this.eventId).then(response => {
        const vocab = response.data.data.data
        const loader = vocab.vocabLoaders
        this.studentsForWords = loader.map(i => i.user_id)
        this.wordStartDate = vocab.start_time
        this.wordEndDate = vocab.end_time
        this.answerDeliveryOption = loader[0]?.rule?.type || null
        this.selectedWords = response.data.data.words
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    getStudentsForVw() {
      useApollo.getClassRoomStudents(this.$route.params.classId).then(response => {
        this.studentsList = response.data?.room?.students.map(student => ({
          ...student,
          id: Number(student.id),
        }))
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
    removeWord(wordIndex) {
      this.selectedWords.splice(wordIndex, 1)
    },
    vocabStartDateChanged() {
      if (new Date(this.wordEndDate).getTime() < new Date(this.wordStartDate).getTime()) {
        this.wordEndDate = this.wordStartDate
      }
    },
    createWordProblem() {
      if (this.eventId) {
        this.$emit('update')
        return
      }
      this.$refs.refFormObserver.validate().then(async success => {
        console.log(success)
        if (success) {
          this.isProcessing = true
          const formData = new FormData()

          this.selectedWords.forEach(word => {
            formData.append('word_ids[]', word.id)
          })

          this.studentsForWords.forEach(user => {
            formData.append('user_ids[]', user)
          })
          // lesson id for Vocab words from follow up
          // formData.append('lesson_id', 2808)
          formData.append('lesson_id', 2072) // for local
          // formData.append('wordCsv', this.wordCsv)
          formData.append('start_time', this.wordStartDate)
          formData.append('end_time', this.wordEndDate)
          formData.append('class_id', this.$route.params.classId)
          formData.append('answer_delivery_option', this.answerDeliveryOption)
          useJwt.createVocabEventProblem(formData)
            .then(response => {
              this.searchWord = ''
              this.searchedWords = []
              this.selectedWords = []
              this.missingWords = []
              this.studentsForWords = []
              this.wordStartDate = ''
              this.wordEndDate = ''
              // this.activeTab = 'vocabWordsProblem'
              this.answerDeliveryOption = ''
              this.showSuccessMessage(response)
              this.resetForm()
              this.$emit('refetch-events')
            }).catch(error => {
              this.showErrorMessage(error)
            }).finally(() => {
              this.isProcessing = false
            })
        }
      })
    },
    uploadVocabCsv() {
      const formData = new FormData()
      formData.append('wordCsv', this.wordCsv)
      useJwt.uploadVocabCsv(formData)
        .then(response => {
          this.selectedWords = response.data.data?.imageLibrary
          this.missingWords = response.data.data?.missingWords
        })
    },
    searchWordFromIL() {
      this.isSearching = true
      if (!this.searchWord) {
        this.searchedWords = []
        this.isSearching = false
      } else {
        useJwt.getImageLibrary({
          params: {
            term: this.searchWord,
            lang: 'english',
            getAll: true,
          },
        }).then(response => {
          this.isSearching = false
          this.searchedWords = response.data.data.map(word => ({
            id: word.id,
            vocab_word: word.vocab_word,
            synonyms: word.synonyms,
          }))
        })
      }
    },
  },
}
</script>
